import React, { useState } from 'react'
import { signatureValidator } from 'utils/formValidator'

import { Formik, Form, Field } from 'formik'

import { FormData } from 'types'

import 'styling/style.css'

const App: React.FC = () => {
	const [status, setStatus] = useState<string>('')
	const [data, setData] = useState<FormData>({name: '', surname: '', position: '', phone: '', email: ''})
	function generateSignature(values: FormData) {
		setData(values)
		setStatus('done')
	}
	
	return status !== 'done' ? (
		<Formik initialValues={{name: '', surname: '', position: '', phone: '', email: ''}} validateOnChange={false} validateOnBlur={false} validationSchema={signatureValidator} onSubmit={values => generateSignature(values)} render={formikProps => (
			<Form className="form-signin">
				<img className="mb-4 logo" src={require('media/logo.svg')} alt="Slesh"/>
				<Field type="text" name="name" className="form-control first" placeholder="Nome"/>
				<Field type="text" name="surname" className="form-control" placeholder="Cognome"/>
				<Field type="text" name="position" className="form-control" placeholder="Posizione"/>
				<Field type="text" name="phone" className="form-control" placeholder="Numero di telefono"/>
				<Field type="email" name="email" className="form-control last" placeholder="Email"/>
				{(formikProps.errors.name || formikProps.errors.surname || formikProps.errors.position || formikProps.errors.phone || formikProps.errors.email) && <p className="red-text">Completa correttamente il form</p>}
				<button className="btn btn-lg btn-primary btn-block" type="submit">Genera</button>
			</Form>
		)} />
	) : (
		<div className="generated-signature">
			<hr/>
			<p className="name black">{data.name} {data.surname}</p>
			<p className="position">{data.position}</p>
			<p><span className="black">Tel:</span> <a href={`tel:+39${data.phone}`}>{`+39 ${data.phone}`}</a></p>
			<p><span className="black">Email:</span> <a href={`mailto:${data.email}`}>{data.email}</a></p>
			<p><a href="https://slesh.it">slesh.it</a></p>
			<a href="https://slesh.it">
				<img src="https://static.slesh.it/mail-logo.png" alt="Slesh" className="logo-gen"/>
			</a>
			<hr/>
			<p className="small">I contenuti di questa email e dei file allegati sono confidenziali e destinati solamente alle persone alle quali sono stati indirizzati. Nel caso aveste ricevuto questa e-mail per errore, vi preghiamo di eliminarla: riferire, copiare, distribuire o agire in relazione alle informazioni in essa contenute è strettamente proibito.</p>
			<p className="small">This email and any files transmitted with it are confidential and intended solely for the use of the individual to whom they are addressed. If you are not the intended recipient you are notified that disclosing, copying, distributing or taking any action in reliance on the contents of this information is strictly prohibited.</p>
		</div>
	)
}

export default App